import { buildAnnotateImage, carHeader, CarHeader, ClassificationResult, downloadDataset, predictModel, trainModel, TrainParams, uploadDataset } from '@japieglobal/shared/src/api';
import { ColoredButton, ColoredOutlinedButton, SelectInput, SelectOption, TextInput } from "@japieglobal/shared/src/components";
import { useSnackbarErrorHandler } from "@japieglobal/shared/src/hooks";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { default as React, useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const possibleAttributes: SelectOption[] = [
  { label: "body", value: "body" }, { label: "model year", value: "model_year" }, { label: "four doors", value: "equipment_four_doors" }
];

const useStyles = makeStyles(() => ({
  main: {
    padding: 20,
    margin: 20,
  },
  container: {
    color: '#616161',
  },
  dropMessage: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}));

export const TrainDialog = ({ open, onClose, initialParams }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler, snackbarSuccessMessage, snackbarErrorMessage } = useSnackbarErrorHandler();
  const [trainParams, setTrainParams] = useState<TrainParams>(initialParams || {});
  const [availableModels, setAvailableModels] = useState<SelectOption[]>([]);

  const onStartTrain = () => {
    if (!trainParams?.model || !trainParams?.attr) {
      snackbarErrorMessage("Model and attribute are required");
      return;
    }
    setIsLoading(true);
    trainModel(trainParams).then(() => {
      onClose();
      snackbarSuccessMessage('Training started');
    }).catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    carHeader({ dealer_specific: false, type: CarHeader.MODEL }).then((data) =>
      setAvailableModels((data.headers as string[]).map((model) => ({ label: model, value: model }))),
    ).catch(snackbarErrorHandler);
  }, [snackbarErrorHandler]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Train Photo Detection</DialogTitle>
      <DialogContent>
        <Box mb={2} mt={1}>
          <SelectInput label={"Model"} value={{ label: trainParams?.model || "", value: trainParams?.model || "" }} setValue={(option) => setTrainParams({ ...trainParams, model: option.value })} options={availableModels} />
        </Box>
        <Box mb={1}>
          <SelectInput label={"Attribute"} value={{ label: trainParams?.attr || "", value: trainParams?.attr || "" }} setValue={(option) => setTrainParams({ ...trainParams, attr: option.value })} options={possibleAttributes} />
        </Box>
        {isLoading && <Box><CircularProgress /></Box>}
      </DialogContent>
      <DialogActions>
        <ColoredOutlinedButton onClick={onClose}>Cancel</ColoredOutlinedButton>
        <ColoredButton onClick={onStartTrain}>Start</ColoredButton>
      </DialogActions>
    </Dialog>
  );
};

export const DetectionDialog = ({ open, onClose, model }) => {
  const [url, setUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<ClassificationResult | undefined>();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();
  const formatPercent = (value: number) => Math.round(value * 1000) / 10;

  const onSubmit = () => {
    if (!url || !model) return;
    setIsLoading(true);
    setResult(undefined);
    predictModel({ image_url: url, model: model.model, attr: model.attr }).then(setResult).catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  };
  useEffect(() => setResult(undefined), [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Photo Detection for {model.model}: {model.attr}</DialogTitle>
      <DialogContent>
        <Box mb={2}>Paste url for photo detection:</Box>
        <TextInput label={t('URL')} value={url} setValue={setUrl} required width={500} />
        {isLoading && <Box>
          <CircularProgress />
        </Box>}
        {result &&
          <>
            {result.error ? <div>{result.error}</div> :
              <div>
                <Box mt={2}>
                  {result[result.attr]}: {formatPercent(result.probability)}%
                </Box>
                <Box style={{ fontSize: 13 }}>
                  <Box component="span" mr={1}>Possible values:</Box>
                  {Object.entries(result.possible_values || {}).map(([k, prob]) => <Box component="span" mr={1}>
                    {k}: {formatPercent(prob)}%
                  </Box>)}
                </Box>
                <Box mt={2}>
                  {url && <img src={buildAnnotateImage(url)} />}
                </Box>
              </div>}
          </>}
      </DialogContent>
      <DialogActions>
        <ColoredOutlinedButton onClick={onClose}>Cancel</ColoredOutlinedButton>
        <ColoredButton onClick={onSubmit}>Detect</ColoredButton>
      </DialogActions>
    </Dialog>
  );
};

export const DatasetDialog = ({ open, onClose, model, attr }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const { t } = useTranslation();
  const classes = useStyles();
  const [params, setParams] = useState({ model, attr });
  const [availableModels, setAvailableModels] = useState<SelectOption[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIsLoading(true);
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      uploadDataset(formData, params).then(() => {
        snackbarSuccessMessage(t('PHOTO_DETECTOR_UPLOAD_SUCCESS'));
      }).catch(snackbarErrorHandler).finally(() => setIsLoading(false));
    }, [snackbarSuccessMessage, snackbarErrorHandler, t, params]);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop });

  const activeStyle = { borderColor: '#2196f3' };
  const acceptStyle = { borderColor: '#96BF31' };
  const rejectStyle = { borderColor: '#ff1744' };

  const baseStyle = {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept],
  );

  const onDownload = useCallback(async () => {
    setIsLoading(true);
    await downloadDataset(params).catch(snackbarErrorHandler).finally(() => setIsLoading(false));
  }, [params, snackbarErrorHandler]);

  useEffect(() => {
    carHeader({ dealer_specific: false, type: CarHeader.MODEL }).then((data) =>
      setAvailableModels((data.headers as string[]).map((model) => ({ label: model, value: model }))),
    ).catch(snackbarErrorHandler);
  }, [snackbarErrorHandler]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Dataset for Photo Detection</DialogTitle>
      <DialogContent>
        {isLoading && <Box><CircularProgress /></Box>}
        <Grid2 container>
          <Grid2 size={12} mb={3}>
            <code>
              <Box>Upload a zip file containing images in the following format:</Box>
              <Box ml={2}>- CLASS1:</Box>
              <Box ml={4}>- img1.jpg</Box>
              <Box ml={4}>- img2.jpg</Box>
              <Box ml={2}>- CLASS2:</Box>
              <Box ml={4}>- img3.jpg</Box>
              Where CLASS1 and CLASS2 are options for classification in the context of {params.attr || "selected attribute"} (e.g., CLASS1={params.attr === "body" ? "SmallCar" : "2020"}).
            </code>
          </Grid2>
          <Grid2 size={6}>
            <SelectInput label={"Model"} value={{ label: params.model || "", value: params.model || "" }} setValue={(option) => setParams({ ...params, model: option.value })} options={availableModels} />
          </Grid2>
          <Grid2 size={6}>
            <SelectInput label={"Attribute"} value={{ label: params.attr || "", value: params.attr || "" }} setValue={(option) => setParams({ ...params, attr: option.value })} options={possibleAttributes} />
          </Grid2>
          <Grid2 className={classes.container} size={9}>
            <div {...getRootProps({ style })} className={classes.main}>
              <input {...getInputProps()} />
              <div className={classes.dropMessage}>
                <img src="/dragdrop.png" width={160} alt="drag-n-drop" />
                <div>{t('PHOTO_DETECTOR_DROP_MESSAGE')}</div>
              </div>
            </div>
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <ColoredOutlinedButton onClick={onClose}>Close</ColoredOutlinedButton>
        <ColoredButton onClick={onDownload}>Download</ColoredButton>
      </DialogActions>
    </Dialog>
  )
}
