import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ResultMoreInfoPopupContent } from './result-more-info-popup-content';
import { ColoredButtonLargeText } from '../../monitoring/results/result-table-styles';
import { useInfoColorsStyles } from '../../monitoring/results/info-colors-styles';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { InformationPopover } from '@japieglobal/shared/src/components/information-popover';

const ColorSpan = styled('span')({
  position: 'absolute',
  top: '2px',
  right: '4px',
  width: '5px',
  height: '5px',
});

const IconButton = styled(ColoredButtonLargeText)({
  width: '35px',
  height: '30px',
  fontSize: '15px',
});

interface ResultTableAdditionalInformationProps {
  car: CarResult;
  interactiveTooltip?: boolean;
}

export const ResultTableAdditionalInformation = ({
  car,
  interactiveTooltip = false,
}: ResultTableAdditionalInformationProps) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const styles = useInfoColorsStyles();

  const getInformation = useCallback(
    () => ResultMoreInfoPopupContent({ user, car, t, styles }),
    [user, car, t, styles],
  );

  const displayWarning = useMemo(() => {
    if (car.stat?.price_warning === 1) {
      return styles.orange;
    }
    if (car.stat?.price_warning === 2) {
      return styles.red;
    }
    if (car.stat?.price_warning === 3) {
      return styles.black;
    }
    return undefined;
  }, [car.stat?.price_warning, styles.black, styles.orange, styles.red]);

  return (
    <InformationPopover
      interactiveTooltip={interactiveTooltip}
      parentKey={`${car.id}-additional-info`}
      information={getInformation()}
    >
      <IconButton className="questionMarkButton">
        ?{displayWarning ? <ColorSpan className={displayWarning} /> : undefined}
      </IconButton>
    </InformationPopover>
  );
};
